import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AktuellesPage = () => (
  <Layout>
    <SEO title="Aktuelles" />

    <h1 id="ueberschrift">Veranstaltungen &amp; mehr</h1>
    <Link to="/fdml_1a_aktuelles-klartext#ueberschrift" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" style={{fontSize: "1.5em"}}>zum Klartext</Link>

    <p className="poem" style={{marginTop: "0.5em"}}>
      „Der Mensch ist klug“ doch weiß er’s auch?<br />
      Hält er den Mund, da so der Brauch?<br />
      Bei diesen Treffen geht’s ums Wissen<br />
      und um viele Leckerbissen<br />
      für deinen Geist und / oder Magen,<br />
      für ganz verschiedene Lebenslagen…<br />
      Zusammen lernt sich‘s immer Gut.<br />
      Hier hast Du Spaß und lernst auch Mut.<br />
      Egal was kommt aus Deinem Mund:<br />
      Dein Wissen kannst Du geben kund.<br />
      Ein jeder hat „sein“ Fachgebiet,<br />
      Auch wenn er selber das nicht sieht.<br />
      Je nach Rubrik kannst Du es wagen<br />
      auch einmal etwas vorzutragen.
    </p>

    <p>Leider noch im Wiederaufbau:</p>

    <p className="poem">
      Im <a href="//www.bastet-project.de" title="zur Webseite vom Bastet-Project" style={{ fontSize: "1em"  }}>BASTET-PROJECT</a> findest Du<br />
      Ideen, die in aller Ruh<br />
      Entstanden sind auf solchen Treffen,<br />
      Wo‘s nicht drum geht herum zu kläffen.
    </p>
  </Layout>
              )

              export default AktuellesPage
